<template>
  <div class="h-full px-8 2xl:px-0 text-primary">
    <router-view/>
  </div>
</template>

<script>
import './app.css'

export default {
  name: 'App',
}
</script>

<style>
html, body {
  font-size: 16px;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
