import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '../i18n'

const LOCALES = ['en', 'de', 'ru', 'fr', 'it', 'ko', 'pl', 'es']

const routes = [
  {
    path: '/',
    redirect: '/en'
  },
  {
    path: '/:locale',
    component: {
      template: '<router-view></router-view>'
    },
    beforeEnter: (to, from, next) => { 
      const locale = to.params.locale; 
      
      if (!LOCALES.includes(locale)) return next('en'); 

      if (i18n.locale !== locale) {
        if (i18n.mode === 'legacy') {
          i18n.global.locale = locale
        } else {
          i18n.global.locale.value = locale
        }
      }

      return next() 
    },
    children: [{
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'acss-part-a',
        name: 'AcssA',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "acssA" */ '../views/AcssA.vue')
      },
      {
        path: 'acss-part-b',
        name: 'AcssB',
        component: () => import(/* webpackChunkName: "acssB" */ '../views/AcssB.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
