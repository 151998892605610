export default {
  en: {
    home: {
      title: 'ACSS Questionnaire',
      backtorecap: 'Back to recap',
      recapurl: 'https://recap-cystitis.com/',
      description: 'The Acute Cystitis Symptom Score (ACSS) is a self-reporting symptom questionnaire to assess the severity of symptoms in women with symptomatic lower urinary tract infections (LUTIs) and their impact on quality of life as well as to differentiate from other urogenital disorders with the possibility to monitor treatment efficacy.',
      test_a: 'TEST PART A',
      test_a_sub: '(if this is the first time you take the test)',
      test_b: 'TEST PART B',
      test_b_sub: '(follow-up form)',
      footer_info_1: 'English (US): Alidjanov et al., 2020, Antibiotics. "Validation of the American English Acute Cystitis Symptom Score." Antibiotics 9(12): 929. Retrieved from: https://www.mdpi.com/2079-6382/9/12/929',
      footer_info_2: 'Russian ACSS: Alidjanov, J. F., Abdufattaev, U. A., Makhmudov, Dk., Mirkhamidov, Dk., Khadzhikhanov, F. A., Azgamov, A. V, … Akilov, F. A. (2014). [Development and clinical testing of the Russian version of the Acute Cystitis Symptom Score - ACSS]. Urologiia, (6), 14–22. Retrieved from http://www.ncbi.nlm.nih.gov/pubmed/25799721',
      footer_info_3: 'J.F. Alidjanov, A. Pilatz, U.A. Abdufattaev, J.Wiltink, W.Weidner, K.G. Naber, F. Wagenlehner. Kommentar zumBeitrag: „Acute Cystitis SymptomScore”. Urologe 2015;54:1269-76. Doi: 10.1007/s00120-015-3873-5 Neuer Fragebogen zur deutschen Validierung des „Acute Cystitis Symptom Score”. Der Urologe 2017; 56:364–366. DOI: 10.1007/s00120-017-0327-2 (Open Access). http://link.springer.com/article/10.1007/s00120-017-0327-2?wt_mc=Internal.Event.1.SEM.ArticleAuthorOnlineFirst',
      footer_info_4: 'Submitted to «Progrès en Urologie» journal',
      download_pdf: 'Download PDF'
    },
    acssa: {
      first_visit: 'First visit (diagnostic form)',
      title: 'ACSS Questionnaire - ',
      title_sub: 'Part A',
      res_desc: 'Caution: Although the ACSS is among the most powerful diagnostic tools, results of self- assessment by ACSS cannot guarantee precise diagnosis. If you have any suspicious symptoms, we strongly recommend to consult a physician',
      res_desc_sub: 'Probability of cystitis depending on the summary score of «Typical» domain.',
      res_desc_sub_2: 'It is recommended to use part B for follow up after 3 days, 1 week & 2 weeks, but you can use it every day.',
      res_points: 'point',
      res_score_typical: 'Score of "Typical"',
      res_sum_score_typical: 'Summary score of "Typical"',
      res_probability_ac: 'Probability of Acute Cystitis (AC)',
      res_score_differential: 'Score of "Differential"',
      res_score_qol: 'Score of "QoL"',
      low: '< 50% (low)',
      moderate: '75 - 83% (moderate)',
      high: '≥ 90% (high)',
      error: 'Error',
      error_desc: 'Please fill out every question'
    },
    acssb: {
      visit: 'Control visit (follow‐up form)',
      first_treatment: 'When did you start your treatment?',
      title: 'ACSS Questionnaire - ',
      title_sub: 'Part B',
      res_desc: 'Caution: Although the ACSS is among the most powerful diagnostic tools, results of self- assessment by ACSS cannot guarantee precise diagnosis. If you have any suspicious symptoms, we strongly recommend to consult a physician',
      res_desc_sub: 'Probability of cystitis depending on the summary score of «Typical» domain.',
      res_points: 'point',
      res_score_dynamics: 'Score of "Dynamics"',
      res_score_typical: 'Score of "Typical"',
      res_sum_score_typical: 'Summary score of "Typical"',
      res_probability_ac: 'Probability of Acute Cystitis (AC)',
      res_score_differential: 'Score of "Differential"',
      res_score_qol: 'Score of "QoL"'
    },
    lutire: {
      low_risk: 'Low risk',
      mo_risk: 'Moderate risk',
      hi_risk: 'High risk',
      lo_point_range: '0 - 99',
      mo_point_range: '91 - 150',
      hi_point_range: '151 - 250',
      lo_probability: '.20 - .45',
      mo_probability: '.46 - .70',
      hi_probability: '.71 - .99',
      lo_action: 'Evaluation of risk factors and counselling',
      mo_action: 'Evaluation of risk factors, counselling and modify lifestyle with follow-up evaluations; active prophylaxis is recommended in motivated patients',
      hi_action: 'Evaluation of risk factors, counselling and active prophylaxis is required in all patients. Follow-up evaluation is required',
    }
  },
  ru: {
    home: {
      title: 'Анкета ACSS',
      backtorecap: 'назад к Recap',
      recapurl: 'https://recap-cystitis.com/',
      description: 'Шкала Оценки Симптомов Острого Цистита (The Acute Cystitis Symptom Score - ACSS) была специально разработана для женщин и представляет собой анкету для самостоятельной оценки симптомов острой инфекции нижних мочевыводящих путей (острого цистита). В том числе анкета предназначена для оценки влияния вышеуказанных симптомов на качество жизни, а также для дифференцирования (разграничения) острого цистита от других мочеполовых расстройств с возможностью наблюдения за эффективностью проводимого лечения.',
      test_a: 'ЧАСТЬ A',
      test_a_sub: '(для проходящих тест в первый раз)',
      test_b: 'ЧАСТЬ Б',
      test_b_sub: '("диспансерная")',
      footer_info_1: 'English (US): Alidjanov et al., 2020, Antibiotics, J. F., et al. (2020). "Validation of the American English Acute Cystitis Symptom Score." Antibiotics 9(12): 929. Retrieved from: https://www.mdpi.com/2079-6382/9/12/929',
      footer_info_2: 'Russian: Alidjanov et al., 2014, Urologiia, J. F., Abdufattaev, U. A., Makhmudov, Dk., Mirkhamidov, Dk., Khadzhikhanov, F. A., Azgamov, A. V, … Akilov, F. A. (2014). [Development and clinical testing of the Russian version of the Acute Cystitis Symptom Score - ACSS]. Urologiia, (6), 14–22. Retrieved from http://www.ncbi.nlm.nih.gov/pubmed/25799721',
      download_pdf: 'Скачать версию в PDF'
    },
    acssa: {
      first_visit: 'Первое посещение ("диагностическая")',
      title: 'Анкета ACSS - ',
      title_sub: 'Часть А',
      res_desc: 'Внимание! Несмотря на то, что ACSS является одним из надежных инструментов для самодиагностики, полученный результат не может претендовать на стопроцентную точность. В случае возникновения малейших сомнений о точности, и/или появления других симптомов, настоятельно рекомендуем обратиться к врачу.',
      res_desc_sub: 'Вероятность наличия острого цистита, исходя из суммарного балла секции «Типичных» симптомов',
      res_desc_sub_2: 'Рекомендуется заполнить часть Б анкеты для контроля спустя 3 дня, 1 неделю и 2 недели, но Вы также можете заполнять часть Б ежедневно.',
      res_points: 'баллов',
      res_score_typical: 'Суммарно по "Типичным» симптомам"',
      res_sum_score_typical: 'Суммарный балл "Типичных» симптомов"',
      res_probability_ac: 'Уровень вероятности наличия острого цистита (ОЦ)',
      res_score_differential: 'Суммарно по "Дифференциальным" симптомам',
      res_score_qol: 'Суммарно по "Качеству жизни"',
      low: '< 50% (низкий)',
      moderate: '75 - 83% (средний)',
      high: '≥ 90% (высокий)',
      error: 'Ошибка',
      error_desc: 'Пожалуйста, заполните все вопросы'
    },
    acssb: {
      visit: 'Контрольное посещение ("диспансерная")',
      first_treatment: 'Когда вы начали лечение?',
      title: 'Анкета ACSS - ',
      title_sub: 'Часть Б',
      res_desc: 'Внимание! Несмотря на то, что ACSS является одним из надежных инструментов для самодиагностики, полученный результат не может претендовать на стопроцентную точность. В случае возникновения малейших сомнений о точности, и/или появления других симптомов, настоятельно рекомендуем обратиться к врачу.',
      res_desc_sub: 'Вероятность наличия острого цистита, исходя из суммарного балла секции «Типичных» симптомов',
      res_points: 'баллов',
      res_score_dynamics: 'Балл секции "Динамика"',
      res_score_typical: 'Суммарно по "Типичным» симптомам"',
      res_sum_score_typical: 'Суммарный балл "Типичных» симптомов"',
      res_probability_ac: 'Уровень вероятности наличия острого цистита (ОЦ)',
      res_score_differential: 'Суммарно по "Дифференциальным" симптомам',
      res_score_qol: 'Суммарно по "Качеству жизни"'
    }
  },
  de: {
    home: {
      title: 'ACSS‐Fragebogen',
      backtorecap: 'Zurück zu recap',
      recapurl: 'https://recap-cystitis.com/',
      description: 'Der Acute Cystitis Symptom Score (ACSS) Fragebogen kann selber ausgefüllt werden und dient zur Beurteilung der Schwere der Symptome bei Frauen mit symptomatischen Infektionen der unteren Harnwege (Harnblasenentzündung) und ihrer Auswirkungen auf die Lebensqualität sowie zur Unterscheidung von anderen urogenitalen Störungen mit der Möglichkeit zur Überwachung der Wirksamkeit der Behandlung.',
      test_a: 'ACSS Teil A',
      test_a_sub: '(wenn Sie den Test zum ersten Mal machen)',
      test_b: 'TEST TEIL B',
      test_b_sub: '(Folgebefund)',
      footer_info_1: 'German: Alidjanov et al., 2017, Der Urologe, J. F., et al. (2020). "Validation of the American English Acute Cystitis Symptom Score." Antibiotics 9(12): 929. Retrieved from: https://www.mdpi.com/2079-6382/9/12/929',
      footer_info_2: 'Russian ACSS: Alidjanov, J. F., Abdufattaev, U. A., Makhmudov, Dk., Mirkhamidov, Dk., Khadzhikhanov, F. A., Azgamov, A. V, … Akilov, F. A. (2014). [Development and clinical testing of the Russian version of the Acute Cystitis Symptom Score - ACSS]. Urologiia, (6), 14–22. http://www.ncbi.nlm.nih.gov/pubmed/25799721',
      footer_info_4: 'Submitted to «Progrès en Urologie» journal',
      download_pdf: 'PDF herunterladen'
    },
    acssa: {
      first_visit: 'Erstvorstellung (Diagnose)',
      title: 'ACSS‐Fragebogen - ',
      title_sub: 'Teil A',
      res_desc: 'Achtung: Obwohl das ACSS zu den leistungsstärksten Diagnosewerkzeugen gehört, können die Ergebnisse der Selbstbewertung durch den ACSS keine genaue Diagnose garantieren. Wenn Sie verdächtige Symptome haben, empfehlen wir dringend, einen Arzt zu konsultieren.',
      res_desc_sub: 'Wahrscheinlichkeit einer Blasenentzündung in Abhängigkeit von der Gesamtbewertung der Domäne "Typisch"',
      res_desc_sub_2: 'Es wird empfohlen, Teil B für die Nachsorge nach 3 Tagen, 1 Woche und 2 Wochen zu verwenden.',
      res_points: 'Punkte',
      res_score_typical: 'Gesamtpunktzahl von "Typische Symptome"',
      res_sum_score_typical: 'Total von "Typische Symptome"',
      res_probability_ac: 'Wahrscheinlichkeit einer akuten Blasenentzündung (AC)',
      res_score_differential: 'Gesamtpunktzahl von "Differentialdiagnose"',
      res_score_qol: 'Gesamtpunktzahl von "Lebensqualität"',
      low: '< 50% (niedrig)',
      moderate: '75 - 83% (mäßig)',
      high: '≥ 90% (hoch)',
      error: 'Fehler',
      error_desc: 'Bitte füllen Sie alle Fragen aus'
    },
    acssb: {
      visit: 'Kontrollvorstellung (Folgebefund)',
      first_treatment: 'Wann haben Sie mit Ihrer Behandlung begonnen?',
      title: 'ACSS‐Fragebogen - ',
      title_sub: 'Teil B',
      res_desc: 'Achtung: Obwohl das ACSS zu den leistungsstärksten Diagnosewerkzeugen gehört, können die Ergebnisse der Selbstbewertung durch das ACSS keine genaue Diagnose garantieren. Wenn Sie verdächtige Symptome haben, empfehlen wir dringend, einen Arzt zu konsultieren.',
      res_desc_sub: 'Wahrscheinlichkeit einer Blasenentzündung in Abhängigkeit von der Gesamtbewertung der typischen Symptome.',
      res_points: 'Punkte',
      res_score_dynamics: 'Gesamtpunktzahl von "Dynamik"',
      res_score_typical: 'Gesamtpunktzahl von "Typische Symptome"',
      res_sum_score_typical: 'Gesamtpunkte von "Typische Symptome"',
      res_probability_ac: 'Wahrscheinlichkeit einer akuten Harnblasenentzündung',
      res_score_differential: 'Gesamtpunktzahl von "Differentialdiagnose"',
      res_score_qol: 'Gesamtpunktzahl von "Lebensqualität"'
    }
  },
  fr: {
    home: {
      title: 'ACSS Questionnaire',
      backtorecap: 'retour à recap',
      recapurl: 'https://recap-cystitis.com/',
      description: 'Le score des symptômes de la cystite aiguë (ACSS) est un auto-questionnaire qui permet d’évaluer la gravité des symptômes chez les femmes présentant un tableau d’infection des voies urinaires basses, leur impact sur la qualité de vie ainsi que pour différencier d’autres troubles urogénitaux avec la possibilité de suivre l’efficacité du traitement.',
      test_a: 'TEST PARTIE A',
      test_a_sub: '(si c\'est la première fois que vous passez le test)',
      test_b: 'TEST PARTIE B',
      test_b_sub: '(formulaire de suivi)',
      footer_info_1: 'French: Bruyère et al., 2022, Progrès en urologie, J. F., et al. (2020). "Validation of the American English Acute Cystitis Symptom Score." Antibiotics 9(12): 929. Retrieved from: https://www.mdpi.com/2079-6382/9/12/929',
      footer_info_2: 'Russian ACSS: Alidjanov, J. F., Abdufattaev, U. A., Makhmudov, Dk., Mirkhamidov, Dk., Khadzhikhanov, F. A., Azgamov, A. V, … Akilov, F. A. (2014). [Development and clinical testing of the Russian version of the Acute Cystitis Symptom Score - ACSS]. Urologiia, (6), 14–22. Retrieved from http://www.ncbi.nlm.nih.gov/pubmed/25799721',
      footer_info_3: 'J.F. Alidjanov, A. Pilatz, U.A. Abdufattaev, J.Wiltink, W.Weidner, K.G. Naber, F. Wagenlehner. Kommentar zumBeitrag: „Acute Cystitis SymptomScore”. Urologe 2015;54:1269-76. Doi: 10.1007/s00120-015-3873-5 Neuer Fragebogen zur deutschen Validierung des „Acute Cystitis Symptom Score”. Der Urologe 2017; 56:364–366. DOI: 10.1007/s00120-017-0327-2 (Open Access). http://link.springer.com/article/10.1007/s00120-017-0327-2?wt_mc=Internal.Event.1.SEM.ArticleAuthorOnlineFirst',
      footer_info_4: 'Submitted to «Progrès en Urologie» journal',
      download_pdf: 'télécharger pdf'
    },
    acssa: {
      first_visit: 'Première visite (diagnostique)',
      title: 'ACSS Questionnaire - ',
      title_sub: 'Partie A',
      res_desc: 'Attention ! Bien que le questionnaire ACSS soit l‘un des outils de diagnostic les plus puissants, les résultats de l‘auto-évaluation ne peuvent garantir un diagnostic précis. En cas de symptômes suspects, nous recommandons vivement de consulter un médecin.',
      res_desc_sub: 'Probabilité de cystite en fonction du score du domaine "Symptômes typiques".',
      res_desc_sub_2: 'Il est recommandé d\'utiliser la partie B pour le suivi après 3 jours, 1 semaine et 2 semaines, mais vous pouvez l\'utiliser tous les jours.',
      res_points: 'point',
      res_score_typical: 'Points totaux de “Symptômes typiques”',
      res_sum_score_typical: 'Résumé de "Symptômes typiques"',
      res_probability_ac: 'Probabilité de cystite aiguë (CA)',
      res_score_differential: 'Points totaux de “Symptômes différentiels”',
      res_score_qol: 'Points totaux de “Qualité de vie“',
      low: '< 50% (bas)',
      moderate: '75 - 83% (modéré)',
      high: '≥ 90% (haut)',
      error: 'Erreur',
      error_desc: 'Veuillez répondre à toutes les questions'
    },
    acssb: {
      visit: 'Visite de contrôle (formulaire de suivi)',
      first_treatment: 'Quand avez-vous commencé votre traitement ?',
      title: 'ACSS Questionnaire - ',
      title_sub: 'Partie B',
      res_desc: 'Attention ! Bien que le questionnaire ACSS soit l‘un des outils de diagnostic les plus puissants, les résultats de l‘auto-évaluation ne peuvent garantir un diagnostic précis. En cas de symptômes suspects, nous recommandons vivement de consulter un médecin.',
      res_desc_sub: 'Probabilité de cystite en fonction du score du domaine "Symptômes typiques".',
      res_points: 'point',
      res_score_dynamics: 'Points totaux de "Dynamique"',
      res_score_typical: 'Points totaux de “Symptômes typiques”',
      res_sum_score_typical: 'Résumé de "Symptômes typiques"',
      res_probability_ac: 'Probabilité de cystite aiguë (CA)',
      res_score_differential: 'Points totaux de “Symptômes différentiels”',
      res_score_qol: 'Points totaux de “Qualité de vie“'
    },
    lutire: {
      low_risk: 'Low risk',
      mo_risk: 'Moderate risk',
      hi_risk: 'High risk',
      lo_point_range: '0 - 99',
      mo_point_range: '91 - 150',
      hi_point_range: '151 - 250',
      lo_probability: '.20 - .45',
      mo_probability: '.46 - .70',
      hi_probability: '.71 - .99',
      lo_action: 'Evaluation of risk factors and counselling',
      mo_action: 'Evaluation of risk factors, counselling and modify lifestyle with follow-up evaluations; active prophylaxis is recommended in motivated patients',
      hi_action: 'Evaluation of risk factors, counselling and active prophylaxis is required in all patients. Follow-up evaluation is required',
    }
  },
  it: {
    home: {
      title: 'Questionario ACSS',
      backtorecap: 'torna a recap',
      recapurl: 'https://recap-cystitis.com/',
      description: 'Il questionario Acute Cystitis Symptom Score (ACSS) può essere auto-compilato e viene utilizzato per valutare la gravità dei sintomi nelle donne con infezioni sintomatiche del tratto urinario inferiore (UTI) e il loro impatto sulla qualità della vita, nonché per distinguerli da altri disturbi urogenitali con la possibilità di monitorare l\'efficacia del trattamento.',
      test_a: 'ACSS Parte A',
      test_a_sub: '(se stai facendo il test per la prima volta)',
      test_b: 'TEST Parte B',
      test_b_sub: '(visita di controllo)',
      footer_info_1: 'Italian: Di Vico et al., 2020, Antibiotics, J. F., et al. (2020). "Validation of the American English Acute Cystitis Symptom Score." Antibiotics 9(12): 929. Retrieved from: https://www.mdpi.com/2079-6382/9/12/929',
      footer_info_2: 'Russian ACSS: Alidjanov, J. F., Abdufattaev, U. A., Makhmudov, Dk., Mirkhamidov, Dk., Khadzhikhanov, F. A., Azgamov, A. V, … Akilov, F. A. (2014). [Development and clinical testing of the Russian version of the Acute Cystitis Symptom Score - ACSS]. Urologiia, (6), 14–22. http://www.ncbi.nlm.nih.gov/pubmed/25799721',
      footer_info_4: 'Submitted to «Progrès en Urologie» journal',
      download_pdf: 'Scaricare PDF'
    },
    acssa: {
      first_visit: 'Prima visita (modulo di diagnosi)',
      title: 'Questionario ACSS - ',
      title_sub: 'Parte A',
      res_desc: 'Attenzione! Anche se l‘ACSS è uno degli strumenti diagnostici più potenti, i risultati dell‘autovalutazione non possono garantire una diagnosi accurata.In caso di sintomi sospetti, si consiglia vivamente di consultare un medico.',
      res_desc_sub: 'Probabilità di un\'infezione della vescica in funzione della valutazione complessiva del dominio "Tipico"',
      res_desc_sub_2: 'Si raccomanda di utilizzare la parte B per il follow-up dopo 3 giorni, 1 settimana e 2 settimane.',
      res_points: 'Punti',
      res_score_typical: 'Somma del punteggio “tipico”',
      res_sum_score_typical: 'Totalmente da "“tipico”"',
      res_probability_ac: 'Probabilità di cistite acuta (AC)',
      res_score_differential: 'Somma del punteggio “differenziale”',
      res_score_qol: 'Somma del punteggio “Qualità della vita”',
      low: '< 50% (debole)',
      moderate: '75 - 83% (moderare)',
      high: '≥ 90% (forte)',
      error: 'Errore',
      error_desc: 'Compilare tutte le domande'
    },
    acssb: {
      visit: 'Visita di controllo',
      first_treatment: 'Data della valutazione',
      title: 'Questionario ACSS - ',
      title_sub: 'Parte B',
      res_desc: 'Attenzione! Anche se l‘ACSS è uno degli strumenti diagnostici più potenti, i risultati dell‘autovalutazione non possono garantire una diagnosi accurata.In caso di sintomi sospetti, si consiglia vivamente di consultare un medico.',
      res_desc_sub: 'Probabilità di cistite in base al punteggio complessivo dei sintomi tipici.',
      res_points: 'Punti',
      res_score_dynamics: 'Somma del punteggio "Dinamici"',
      res_score_typical: 'Somma del punteggio “tipico”',
      res_sum_score_typical: 'Totalmente da "“tipico”"',
      res_probability_ac: 'Probabilità di infezione acuta della vescica urinaria',
      res_score_differential: 'Somma del punteggio “differenziale”',
      res_score_qol: 'Somma del punteggio “Qualità della vita”'
    }
  },
  pl: {
    home: {
      title: 'Ankieta ACSS',
      backtorecap: 'z powrotem do recap',
      recapurl: 'https://recap-cystitis.com/',
      description: 'Kwestionariusz Acute Cystitis Symptom Score (ACSS) można wypełnić samodzielnie i służy do oceny stopnia objawów u kobiet z zakażeniami objawowymi dolnych dróg moczowych (zapalenie pęcherza moczowego) oraz ich skutków na jakość życia, a także do odróżniania od innych zaburzeń układu moczowo-płciowego z możliwością nadzorowania skuteczności leczenia. ',
      test_a: 'ACSS część A',
      test_a_sub: '(jeśli przeprowadza Pan/Pani test po raz pierwszy)',
      test_b: 'TEST CZĘŚĆ B',
      test_b_sub: '(orzeczenie następcze)',
      footer_info_1: 'Polish: Alidjanov et al., 2022, Antibiotics, J. F., et al. (2020). "Validation of the American English Acute Cystitis Symptom Score." Antibiotics 9(12): 929. Retrieved from: https://www.mdpi.com/2079-6382/9/12/929',
      footer_info_2: 'Russian ACSS: Alidjanov, J. F., Abdufattaev, U. A., Makhmudov, Dk., Mirkhamidov, Dk., Khadzhikhanov, F. A., Azgamov, A. V, … Akilov, F. A. (2014). [Development and clinical testing of the Russian version of the Acute Cystitis Symptom Score - ACSS]. Urologiia, (6), 14–22. http://www.ncbi.nlm.nih.gov/pubmed/25799721',
      footer_info_4: 'Submitted to «Progrès en Urologie» journal',
      download_pdf: 'Pobierz PDF'
    },
    acssa: {
      first_visit: 'Pierwsza wizyta (diagnostyka)',
      title: 'Ankieta ACSS - ',
      title_sub: 'Część A',
      res_desc: 'Uwaga! Chociaż ACSS jest jednym z najpotężniejszych narzędzi diagnostycznych, wyniki samooceny nie mogą zagwarantować trafnej diagnozy. W przypadku podejrzanych objawów zdecydowanie zaleca się konsultację z lekarzem.',
      res_desc_sub: 'Prawdopodobieństwo zapalenia pęcherza w zależności od oceny ogólnej domeny „typowo”',
      res_desc_sub_2: 'Zaleca się wykorzystanie części B do obserwacji pochorobowej po 3 dniach, 1 tygodniu i 2 tygodniach.',
      res_points: 'Punkty',
      res_score_typical: 'Suma odpowiedzi „Typowe”',
      res_sum_score_typical: 'Łącznie „typowe objawy”',
      res_probability_ac: 'Prawdopodobieństwo ostrego zapalenia pęcherza (AC)',
      res_score_differential: 'Suma odpowiedzi „Różnicowe”',
      res_score_qol: 'Suma punktów „Jakość życia”',
      low: '< 50% (słabo)',
      moderate: '75 - 83% (umiarkowany)',
      high: '≥ 90% (silny)',
      error: 'Błąd',
      error_desc: 'Proszę wypełnić każde pytanie'
    },
    acssb: {
      visit: 'Wizyta kontrolna (wynik)',
      first_treatment: 'Data oceny',
      title: 'Ankieta ACSS - ',
      title_sub: 'Część B',
      res_desc: 'Uwaga! Chociaż ACSS jest jednym z najpotężniejszych narzędzi diagnostycznych, wyniki samooceny nie mogą zagwarantować trafnej diagnozy. W przypadku podejrzanych objawów zdecydowanie zaleca się konsultację z lekarzem.',
      res_desc_sub: 'Prawdopodobieństwo zapalenia pęcherza w zależności od ogólnej oceny typowych objawów.',
      res_points: 'Punkty',
      res_score_dynamics: 'Łączna liczba punktów „dynamika”',
      res_score_typical: 'Suma odpowiedzi „Typowe”',
      res_sum_score_typical: 'Łączne punkty „typowe objawy”',
      res_probability_ac: 'Prawdopodobieństwo ostrego zapalenia pęcherza moczowego',
      res_score_differential: 'Suma odpowiedzi „Różnicowe”',
      res_score_qol: 'Suma punktów „Jakość życia”'
    }
  },
  ko: {
    home: {
      title: 'ACSS 설문지',
      backtorecap: '돌아가기 recap',
      recapurl: 'https://recap-cystitis.com/',
      description: '급성 방광염 증상 점수(The Acute Cystitis Symptom Score, ACSS)는 하부 요로감염을 동반한 여성에서 증상의 중증도와 삶의 질에 미치는 영향을 평가하고, 치료 효과를 모니터링할 수 있는 방법으로 다른 비뇨생식기 장애와 구별하기 위한 자가기입형 설문지입니다.',
      test_a: 'PART A',
      test_a_sub: '(이 설문을 처음하는 경우)',
      test_b: 'PART B',
      test_b_sub: '(치료 후 설문)',
      footer_info_1: 'Korean: Choi et al., 2022, Investigate and clinical urology, J. F., et al. (2020). "Validation of the American English Acute Cystitis Symptom Score." Antibiotics 9(12): 929. Retrieved from: https://www.mdpi.com/2079-6382/9/12/929',
      footer_info_2: 'Russian ACSS: Alidjanov, J. F., Abdufattaev, U. A., Makhmudov, Dk., Mirkhamidov, Dk., Khadzhikhanov, F. A., Azgamov, A. V, … Akilov, F. A. (2014). [Development and clinical testing of the Russian version of the Acute Cystitis Symptom Score - ACSS]. Urologiia, (6), 14–22. http://www.ncbi.nlm.nih.gov/pubmed/25799721',
      footer_info_4: 'Submitted to «Progrès en Urologie» journal',
      download_pdf: 'PDF 다운로드'
    },
    acssa: {
      first_visit: '최초 방문(진단 양식)',
      title: 'ACSS 설문지 - ',
      title_sub: '파트 A',
      res_desc: '주의! ACSS는 가장 강력한 진단 도구 중 하나이지만 ACSS를 사용한 자체 평가 결과는 정확한 진단을 보장할 수 없습니다. 의심되는 증상이 있는 경우 반드시 의사와 상담하시기 바랍니다.',
      res_desc_sub: '"전형적인” 분야의 종합 평가에 따른 방광염의 가능성',
      res_desc_sub_2: '파트 B를 3일, 1주 및 2주 후의 후속 조치에 사용하는 것이 좋습니다.',
      res_points: '점수',
      res_score_typical: '대표적 증상 점수',
      res_sum_score_typical: '“전형적인 증상” 전체',
      res_probability_ac: '급성 방광염(AC)의 가능성',
      res_score_differential: '감별 점수',
      res_score_qol: '<삶의 질> 점수',
      low: '< 50% (낮음)',
      moderate: '75 - 83% (중간)',
      high: '≥ 90% (높음)',
      error: '오류',
      error_desc: '모든 질문을 작성해 주세요'
    },
    acssb: {
      visit: '후속 방문일(후속조치 양식)',
      first_treatment: '언제부터 치료를 시작하셨습니까?',
      title: 'ACSS 설문지 - ',
      title_sub: '파트 B',
      res_desc: '주의! ACSS는 가장 강력한 진단 도구 중 하나이지만 ACSS를 사용한 자체 평가 결과는 정확한 진단을 보장할 수 없습니다. 의심되는 증상이 있는 경우 반드시 의사와 상담하시기 바랍니다.',
      res_desc_sub: '"전형적인” 분야의 종합 평가에 따른 방광염의 가능성',
      res_points: '점수',
      res_score_dynamics: '“역학적 특성”의 총점',
      res_score_typical: '대표적 증상 점수',
      res_sum_score_typical: '“전형적인 증상”의 총점',
      res_probability_ac: '급성 방광염의 가능성',
      res_score_differential: '감별 점수',
      res_score_qol: '<삶의 질> 점수'
    }
  },
  es: {
    home: {
      title: 'Cuestionario ACSS',
      backtorecap: 'volver a recap',
      recapurl: 'https://recap-cystitis.com/',
      description: 'El Acute Cystitis Symptom Score (ACSS) es un cuestionario de síntomas autoinformado para evaluar la gravedad de los síntomas en mujeres con infecciones sintomáticas del tracto urinario inferior (ITUS) y su impacto en la calidad de vida, así como para diferenciar de otros trastornos urogenitales con la posibilidad de monitorizar la eficacia del tratamiento.',
      test_a: 'TEST PARTE A',
      test_a_sub: '(si es la primera vez que realiza el examen)',
      test_b: 'TEST PARTE B',
      test_b_sub: '(formulario de seguimiento)',
      footer_info_1: 'English (US): Alidjanov et al., 2020, Antibiotics. "Validation of the American English Acute Cystitis Symptom Score." Antibiotics 9(12): 929. Retrieved from: https://www.mdpi.com/2079-6382/9/12/929',
      footer_info_2: 'Russian ACSS: Alidjanov, J. F., Abdufattaev, U. A., Makhmudov, Dk., Mirkhamidov, Dk., Khadzhikhanov, F. A., Azgamov, A. V, … Akilov, F. A. (2014). [Development and clinical testing of the Russian version of the Acute Cystitis Symptom Score - ACSS]. Urologiia, (6), 14–22. Retrieved from http://www.ncbi.nlm.nih.gov/pubmed/25799721',
      footer_info_3: 'J.F. Alidjanov, A. Pilatz, U.A. Abdufattaev, J.Wiltink, W.Weidner, K.G. Naber, F. Wagenlehner. Kommentar zumBeitrag: „Acute Cystitis SymptomScore”. Urologe 2015;54:1269-76. Doi: 10.1007/s00120-015-3873-5 Neuer Fragebogen zur deutschen Validierung des „Acute Cystitis Symptom Score”. Der Urologe 2017; 56:364–366. DOI: 10.1007/s00120-017-0327-2 (Open Access). http://link.springer.com/article/10.1007/s00120-017-0327-2?wt_mc=Internal.Event.1.SEM.ArticleAuthorOnlineFirst',
      footer_info_4: 'Submitted to «Progrès en Urologie» journal',
      download_pdf: 'Descargar el PDF'
    },
    acssa: {
      first_visit: 'Primera Visita (Formulario de "diagnòstico")',
      title: 'Cuestionario ACSS - ',
      title_sub: 'Parte A',
      res_desc: '¡Precaución! Aunque el ACSS se encuentra entre las herramientas de diagnóstico más poderosas, los resultados de la autoevaluación que utilizan el ACSS no pueden garantizar un diagnóstico preciso. Si tiene algún síntoma sospechoso, le recomendamos encarecidamente que consulte a un médico.',
      res_desc_sub: 'Probabilidad de cistitis en función de la puntuación resumida del dominio "Típico".',
      res_desc_sub_2: 'Se recomienda utilizar la parte B para el seguimiento después de 3 días, 1 semana y 2 semanas, pero se puede utilizar todos los días.',
      res_points: 'punto',
      res_score_typical: 'Suma de la puntuación de los sintomas "clasicos"',
      res_sum_score_typical: 'Puntuación resumida de "Típico"',
      res_probability_ac: 'Probabilidad de cistitis aguda (CA)',
      res_score_differential: 'Suma de la puntuación de "Diferenciales"',
      res_score_qol: 'Suma de la puntuación de la "Calidad de Vida"',
      low: '< 50% (bajo)',
      moderate: '75 - 83% (moderado)',
      high: '≥ 90% (alta)',
      error: 'Error',
      error_desc: 'rellene todas las preguntas'
    },
    acssb: {
      visit: 'Control visit (follow‐up form)',
      first_treatment: '¿Cuándo empezó el tratamiento?',
      title: 'Cuestionario ACSS - ',
      title_sub: 'Parte B',
      res_desc: '¡Precaución! Aunque el ACSS se encuentra entre las herramientas de diagnóstico más poderosas, los resultados de la autoevaluación que utilizan el ACSS no pueden garantizar un diagnóstico preciso. Si tiene algún síntoma sospechoso, le recomendamos encarecidamente que consulte a un médico.',
      res_desc_sub: 'Probabilidad de cistitis en función de la puntuación resumida del dominio "Típico".',
      res_points: 'punto',
      res_score_dynamics: 'Score of "Dynamics"',
      res_score_typical: 'Suma de la puntuación de los sintomas "clasicos"',
      res_sum_score_typical: 'Puntuación resumida de "Típico"',
      res_probability_ac: 'Probabilidad de cistitis aguda (CA)',
      res_score_differential: 'Suma de la puntuación de "Diferenciales"',
      res_score_qol: 'Suma de la puntuación de la "Calidad de Vida"'
    },
    lutire: {
      low_risk: 'riesgo bajo',
      mo_risk: 'riesgo moderado',
      hi_risk: 'alto riesgo',
      lo_point_range: '0 - 99',
      mo_point_range: '91 - 150',
      hi_point_range: '151 - 250',
      lo_probability: '.20 - .45',
      mo_probability: '.46 - .70',
      hi_probability: '.71 - .99',
      lo_action: 'Evaluación de los factores de riesgo y asesoramiento',
      mo_action: 'Evaluación de los factores de riesgo, asesoramiento y modificación del modo de vida con evaluaciones de seguimiento; se recomienda la profilaxis activa en pacientes motivados',
      hi_action: 'Se requiere la evaluación de los factores de riesgo, asesoramiento y profilaxis activa en todos los pacientes. Se requiere una evaluación de seguimiento',
    }
  }
}
