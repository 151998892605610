//import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const useLanguage = () => {
  const { locale, availableLocales } = useI18n()

  const setLanguage = (lang) => {
    if (availableLocales.includes(lang)) {
      locale.value = lang
    }
  }

  return {
    setLanguage
  }
}

export default useLanguage
