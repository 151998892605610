<template>
	<div class="pt-10 h-full">
    <img
      class="absolute top-0 right-0 z-0"
      :src="require('@/assets/bg-circle.png')"
      style="width: 19.68vw; max-width: 178px"
    />
    <div class="h-full flex flex-col justify-between">
      <div class="relative">
        <div class="md:absolute top-0 left-1/2 transform md:-translay-x-1/2 uppercase bg-no-repeat bg-contain text-center mb-4 md:mb-0 bg-center px-2.5 py-1.5 text-white" style="background-image: url('img/bg.jpg');">
          <div>
            
          </div>
          <div v-if="locale === 'en'">
            <a href="https://recap-cystitis.com">{{ $t("home.backtorecap") }}</a>
          </div>
          <div v-else-if="locale === 'de'">
            <a href="https://recap-cystitis.ch">{{ $t("home.backtorecap") }}</a>
          </div>
          <div v-else-if="locale === 'ru'">
            <a href="https://recap-cystitis.com/ru">{{ $t("home.backtorecap") }}</a>
          </div>
          <div v-else-if="locale === 'fr'">
            <a href="https://recap-cystitis.ch/fr">{{ $t("home.backtorecap") }}</a>
          </div>
          <div v-else-if="locale === 'it'">
            <a href="https://recap-cystitis.com">{{ $t("home.backtorecap") }}</a>
          </div>
          <div v-else-if="locale === 'ko'">
            <a href="https://recap-cystitis.kr/">{{ $t("home.backtorecap") }}</a>
          </div>
          <div v-else-if="locale === 'pl'">
            <a href="https://recap-cystitis.com">{{ $t("home.backtorecap") }}</a>
          </div>
          <div v-else-if="locale === 'es'">
            <a href="https://recap-cystitis.ch/es/hcp/introduction">{{ $t("home.backtorecap") }}</a>
          </div>
        </div>
        <div class="xl:container mx-auto mb-6">
          <button @click="switchLocale('de')" :class="{ 'text-orange': locale === 'de' }" class="mr-4">DE</button>
          <button @click="switchLocale('en')" :class="{ 'text-orange': locale === 'en' }" class="mr-4">EN</button>
          <button @click="switchLocale('ru')" :class="{ 'text-orange': locale === 'ru' }" class="mr-4">RU</button>
          <button @click="switchLocale('fr')" :class="{ 'text-orange': locale === 'fr' }" class="mr-4">FR</button>
          <button @click="switchLocale('it')" :class="{ 'text-orange': locale === 'it' }" class="mr-4">IT</button>
          <button @click="switchLocale('ko')" :class="{ 'text-orange': locale === 'ko' }" class="mr-4">KO</button>
          <button @click="switchLocale('pl')" :class="{ 'text-orange': locale === 'pl' }" class="">PL</button>
          <!-- <button @click="switchLocale('es')" :class="{ 'text-orange': locale === 'es' }" class="">ES</button> -->
        </div>

        <h1 class="text-3xl md:text-5xl xl:container z-10 text-primary mb-6 mx-auto md:mb-12">
          {{ $t("home.title") }}
        </h1>


        <div class="w-full xl:container mx-auto mb-5 relative z-10 h-full">
          <p class="text-base md:text-xl max-w-4xl">{{ $t("home.description") }}</p>
          <div class="mt-9">
            <router-link
              class="text-xl text-orange underline"
              :to="{ name: 'AcssA' }"
              >{{ $t("home.test_a") }}</router-link>
            <span class="ml-4">{{ $t("home.test_a_sub") }}</span>
          </div>
          <div class="mt-9">
            <router-link
              class="text-xl text-orange underline"
              :to="{ name: 'AcssB' }"
            >{{ $t("home.test_b") }}</router-link>
            <span class="ml-4">{{ $t("home.test_b_sub") }}</span>
          </div>
        </div>
      </div>

      <div class="w-full xl:container mx-auto mb-4 mt-10">
        <div v-if="locale === 'de' || locale === 'ru' || locale === 'fr' " class="mb-4">
          <img :src="require('@/assets/om_pharma_logo.jpg')" alt="om pharma logo" />
        </div>
        <div v-else >
          <p class="mb-4 text-sm">Creation of the digital version of the questionnaire supported by OM Pharma</p>
        </div>
        <div class="text-xs">
          <ol>
            <li><p class="max-w-4xl">{{ $t("home.footer_info_1") }}</p></li>
            <li><p class="max-w-4xl">{{ $t("home.footer_info_2") }}</p></li>
            <li><p class="max-w-4xl">{{ $t("home.footer_info_3") }}</p></li>
            <li><p class="max-w-4xl">{{ $t("home.footer_info_4") }}</p></li>
          </ol>
        </div>
      </div>

    </div>
	</div>
</template>

<script>
import useLanguage from '@/composables/useLanguage'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Home',
  setup() {
    const router = useRouter()
    const { setLanguage } = useLanguage()
    const { locale } = useI18n()
    
    const activeLanguage = (lang) => {
      return locale === lang
    }

    const switchLocale = (lang) => {
      if (locale !== lang) {
        setLanguage(lang)
        const to = router.resolve({ params: {locale: lang} })
        router.push(to.fullPath)
      }
    }

    return {
      locale,
      switchLocale,
      activeLanguage
    }
  }
}
</script>
